<template>
    <div ref="container">
        <v-card :loading="loading">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    Invoicing Details /
                    <span class="font-weight-black text-uppercase">
                        PROJECT: {{ project.name }}</span
                    >
                </h2>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="px-4">
                <v-row
                    no-gutters
                    class="quote_tables mt-8"
                    :style="`height: ${height}px`"
                >
                    <v-col
                        cols="12"
                        v-for="quote of Object.keys(invoicesData)"
                        :key="quote"
                        class="mb-6"
                    >
                        <v-data-table
                            :headers="headers"
                            :items="invoicesData[quote].costs"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            multiple-expand
                            :expanded.sync="invoicesData[quote].costs"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    style="background-color: #eeeeee"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="12" class="d-flex">
                                        <h2 class="mt-1">
                                            QUOTE:
                                            {{ invoicesData[quote].quoteName }}
                                        </h2>
                                    </v-col>
                                </v-row>
                            </template>
                            <!-- ITEMS -->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.billedQty`]="{ item }">
                                <p class="my-0">
                                    {{ item.billedQty || 0 }}
                                </p>
                            </template>
                            <template v-slot:[`item.pricePerUnit`]="{ item }">
                                <p class="my-0 text-right">
                                    {{ numberFormat(item.pricePerUnit || 0) }}
                                </p>
                            </template>
                            <template v-slot:[`item.totalPrice`]="{ item }">
                                <p class="my-0 text-right">
                                    {{
                                        numberFormat(
                                            item.qty * item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.amountBilled`]="{ item }">
                                <p class="my-0 text-right">
                                    {{
                                        numberFormat(
                                            item.billedQty *
                                                item.pricePerUnit || 0
                                        )
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.balanceToBill`]="{ item }">
                                <p class="my-0 text-right">
                                    {{
                                        numberFormat(
                                            (item.qty - (item.billedQty || 0)) *
                                                item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>

                            <!-- LOGS TABLE -->
                            <template
                                v-slot:expanded-item="{
                                    headers,
                                    item: item,
                                }"
                            >
                                <td
                                    v-if="item.items && item.items.length > 0"
                                    :colspan="headers.length"
                                    class="pa-4 ma-0 outlined"
                                    style="background: white"
                                >
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-data-table
                                                :headers="logHeaders"
                                                :items="item.items"
                                                class="elevation-0"
                                                :mobile-breakpoint="0"
                                                :loading="loading"
                                                disable-pagination
                                                hide-default-footer
                                            >
                                                <!--ITEMS-->

                                                <template
                                                    v-slot:[`item.amountBilled`]="{
                                                        item,
                                                    }"
                                                >
                                                    <p class="my-0">
                                                        {{
                                                            numberFormat(
                                                                item.billedQty *
                                                                    item.pricePerUnit
                                                            )
                                                        }}
                                                    </p>
                                                </template>

                                                <template
                                                    v-slot:[`item.billedBy`]="{
                                                        item,
                                                    }"
                                                >
                                                    <p class="my-0">
                                                        {{ item.billedBy.name }}
                                                    </p>
                                                </template>
                                                <template
                                                    v-slot:[`item.billedOn`]="{
                                                        item,
                                                    }"
                                                >
                                                    <p class="my-0">
                                                        {{
                                                            formatDate(
                                                                item.billedOn
                                                                    ._seconds
                                                            )
                                                        }}
                                                    </p>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </td>
                            </template>

                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr style="background-color: #eeeeee">
                                    <td
                                        class="font-weight-bold text-center"
                                    ></td>
                                    <td class="font-weight-bold text-center">
                                        TOTALS
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{ invoicesData[quote].totalQty }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{ invoicesData[quote].totalBilledQty }}
                                    </td>
                                    <td
                                        class="font-weight-bold text-center"
                                    ></td>
                                    <td class="font-weight-bold text-right">
                                        {{ numberFormat(project.price) }}
                                    </td>
                                    <td class="font-weight-bold text-right">
                                        {{
                                            numberFormat(project.totalInvoiced)
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-right">
                                        {{
                                            numberFormat(
                                                project.price -
                                                    project.totalInvoiced
                                            )
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!--Error Alert-->
            <Errors />
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'ProjectInvoiceDetails',
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
    },
    data: () => ({
        height: 0,
        loading: false,
        concepts: [],
        categories: [],
        regions: ['international', 'local'],
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'costName',
                sortable: false,
            },
            {
                text: 'TOTAL QTY',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY BILLED',
                value: 'billedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'TOTAL PRICE',
                value: 'totalPrice',
                sortable: false,
            },
            {
                text: 'AMOUNT BILLED',
                value: 'amountBilled',
                sortable: false,
            },
            {
                text: 'BALANCE TO BILL',
                value: 'balanceToBill',
                sortable: false,
            },
        ],
        logHeaders: [
            {
                text: 'Invoice ID',
                value: 'number',
                sortable: false,
            },
            {
                text: 'Billed By',
                value: 'billedBy',
                sortable: false,
            },
            {
                text: 'Date',
                value: 'billedOn',
                sortable: false,
            },
            {
                text: 'Qty Billed',
                value: 'billedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Amount Billed',
                value: 'amountBilled',
                sortable: false,
            },
            {
                text: 'Notes',
                value: 'notes',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        setting: undefined,
        invoicesData: [],
    }),
    async mounted() {
        try {
            this.loading = true
            this.invoices = await API.getInvoices({
                projectId: this.project.id,
            })
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            await this.loadData()
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async loadData() {
            try {
                this.loading = true
                const logInvoices = this.invoices.filter(i =>
                    ['closed', 'billed', 'approved'].includes(i.status)
                )
                for (let invoice of logInvoices) {
                    for (let key in invoice.items) {
                        const {
                            data: { quotes },
                        } = await API.getQuote(key)

                        let quote = this.invoicesData[key]
                            ? this.invoicesData[key]
                            : {
                                  quoteName: quotes.name,
                                  totalQty: 0,
                                  totalBilledQty: 0,
                                  costs: [],
                              }

                        //add costs
                        let costs = await API.getCosts(key)
                        costs = costs.filter(c => c.type == 'cost')
                        costs.forEach(cost => {
                            let newCost = {
                                quoteId: quotes.id,
                                costId: cost.id,
                                costName: cost.reference,
                                qty: Number(cost.quantity),
                                pricePerUnit: Number(cost.pricePerQuantity2),
                                billedQty: 0,
                                items: [],
                            }
                            const exists = quote.costs.some(
                                existingCost =>
                                    existingCost.quoteId === newCost.quoteId &&
                                    existingCost.costId === newCost.costId
                            )

                            if (!exists) {
                                quote.costs.push(newCost)
                                quote.totalQty += Number(cost.quantity)
                            }
                        })
                        //add cost items
                        for (let item of invoice.items[key]) {
                            let index = quote.costs.findIndex(
                                c => c.costId == item.costId
                            )
                            if (index > -1) {
                                item.quoteName = quotes.name
                                item.number = invoice.number
                                item.billedOn = invoice.billedOn
                                item.notes = invoice.notes
                                item.billedBy = this.users.find(
                                    u => u.id == invoice.billedBy
                                )
                                quote.costs[index].billedQty += Number(
                                    item.billedQty || 0
                                )
                                if (item.billedQty && item.billedQty > 0) {
                                    quote.costs[index].items.push(item)
                                }
                            }
                        }

                        quote.totalBilledQty = 0
                        quote.costs.forEach(cost => {
                            if (cost.billedQty) {
                                quote.totalBilledQty += cost.billedQty
                            }
                        })

                        this.invoicesData[key] = quote
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = window.innerHeight - containerHeight + 318
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('L LT')
        },
        numberFormat(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.project.currency,
            }).format(number)
        },

        close() {
            this.$emit('close')
        },
    },
}
</script>

<style>
.quote_tables {
    width: 100%;
    overflow: scroll;
}
</style>
